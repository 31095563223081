.menu {
  position: fixed;
  top: 0;
  right: -300px;
  max-width: 260px;
  width: 100%;
  height: 100vh;
  display: flex;
  opacity: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 15px 10px;
  background: #fff;
  z-index: 999;
  transition: all 0.6s ease;
}

.menu.true {
  opacity: 1;
  right: 0 !important;
}

.menu.false {
  opacity: 0;
  right: -300px;
}

.menu .closeMenu {
  display: flex;
  justify-content: flex-start;
  background: none;
  border: none;
  width: 21px;
  height: 21px;
  margin-bottom: 2px;
}

.closeMenu img {
  width: 100%;
  height: 100%;
}

.highlighting {
  width: 100%;
  background: #ccc4c44d;
  padding: 10px;
}

.highlighting h1 {
  font-size: 12px;
  font-weight: 300;
  line-height: 14.06px;
}

.menuObjects {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding-bottom: 15px;
}

.menuObject {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
