.managerDistricts {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &_name {
    padding: 20px 15px;
    padding-top: 30px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
  }

  &_list {
    display: flex;
    flex-direction: column;

    &_title {
      padding: 10px;
      background: #ccc4c44d;
      font-weight: 400;
    }

    &_item {
      padding: 15px;
      display: flex;
      justify-content: space-between;

      &_switch {
        width: 32px;
        height: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        transition: all 0.3s ease;
        position: relative;

        &er {
          transition: all 0.3s ease;
          position: absolute;
          left: 2px;
          top: 2px;
          height: 12px;
          width: 12px;
          border-radius: 6px;
          background: #fff;
        }
      }
    }
  }
}
